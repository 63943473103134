import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CompanyModel } from "../../types/api";
import axios from "../../plugins/axios";
import { toast } from "react-toastify";

interface ManageUserAccessTabProps {
  company: CompanyModel;
  getCompany: any;
}
interface Inputs {
  manage_plots: boolean;
  delete_plots: boolean;
  manage_trades: boolean;
}
const ManageUserAccessTab: React.FC<ManageUserAccessTabProps> = ({
  company,
  getCompany,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      manage_plots: company.access_control_manage_plots,
      manage_trades: company.access_control_manage_trades,
      delete_plots: company.access_control_delete_plots,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (loading) return;
    setLoading(true);
    try {
      await axios.put(`/admin/company`, {
        access_control_manage_plots: formData.manage_plots,
        access_control_manage_trades: formData.manage_trades,
        access_control_delete_plots: formData.delete_plots
      });
      getCompany();
      toast("Access control Settings saved");
    } catch (error) {}
    setLoading(false);
  };
  return (
    <form className="max-w-xl" onSubmit={handleSubmit(onSubmit)}>
      <label
        className="bg-white rounded-md mb-4 p-6 flex items-center justify-start"
        htmlFor="manage-plots"
      >
        <input
          id="manage-plots"
          type="checkbox"
          {...register("manage_plots")}
          className="w-4 h-4 text-orange-400 bg-gray-100 rounded border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-200 dark:border-gray-200 mr-4"
        />
        <div>
          <h3 className="text-orange-400 font-medium text-md">Manage Plots</h3>
          <p className="text-gray-500">
            Allow non admin users to create and archive plots
          </p>
        </div>
      </label>
      <label
        className="bg-white rounded-md mb-4 p-6 flex items-center justify-start"
        htmlFor="delete-plots"
      >
        <input
          id="delete-plots"
          type="checkbox"
          {...register("delete_plots")}
          className="w-4 h-4 text-orange-400 bg-gray-100 rounded border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-200 dark:border-gray-200 mr-4"
        />
        <div>
          <h3 className="text-orange-400 font-medium text-md">Allow Delete Plots in app</h3>
          <p className="text-gray-500">
            Show / Hide delete plots button in app
          </p>
        </div>
      </label>
      <label className="bg-white rounded-md mb-4 p-6 flex items-center justify-start">
        <input
          id="manage-trades"
          type="checkbox"
          {...register("manage_trades")}
          className="w-4 h-4 text-orange-400 bg-gray-100 rounded border-gray-300 focus:ring-orange-500 dark:focus:ring-orange-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-200 dark:border-gray-200 mr-4"
        />
        <div>
          <h3 className="text-orange-400 font-medium text-md">Manage Trades</h3>
          <p className="text-gray-500">
            Allow non admin users to create new trades
          </p>
        </div>
      </label>
      <div className="flex">
        <button
          type="submit"
          disabled={loading}
          className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
        >
          {loading ? "Loading..." : "Save Settings"}
        </button>
        {isDirty && (
          <div
            className="p-2 text-gray-500 ml-2 hover:text-red-600 cursor-pointer"
            onClick={() =>
              reset({
                manage_plots: company.access_control_manage_plots,
                manage_trades: company.access_control_manage_trades,
              })
            }
          >
            Reset
          </div>
        )}
      </div>
    </form>
  );
};

export default ManageUserAccessTab;

// <div>
//   -- Regular users can create plots / archive plots
//   -- Regular users can create trades
// </div>,
