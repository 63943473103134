import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  TbBuildingPavilon,
  TbAlertOctagon,
  TbUsers,
  TbNotes,
} from "react-icons/tb";
import { PulseLoader } from "react-spinners";
interface CompanyOverviewComponentProps {}

interface NumbersType {
  plots: number;
  openSnags: number;
  closedSnags: number;
  users: number;
  trades: number;
}

const CompanyOverviewComponent: React.FC<
  CompanyOverviewComponentProps
> = () => {
  const [numbers, setNumbers] = useState<NumbersType>({
    plots: 0,
    openSnags: 0,
    closedSnags: 0,
    users: 0,
    trades: 0,
  });

  const [loading, setLoading] = useState(true);

  const getDashboardData = useCallback(async () => {
    try {
      const { data } = await axios.get<NumbersType>("admin/dashboard");
      setNumbers(data);
      setLoading(false);
    } catch (error) {}
  }, [setNumbers]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  if (loading)
    return (
      <div className="p-6">
        <PulseLoader color="#fb923c" size={10} />
      </div>
    );
  return (
    <div className="grid grid-cols-5 gap-5 mt-10">
      <div className="bg-orange-400 text-white rounded-xl shadow-lg p-10 flex items-start justify-between">
        <div>
          <TbBuildingPavilon size={44} />
          <p className="text-md font-light text-gray-100 mt-2">Active Plots</p>
        </div>
        <strong className="text-4xl text-white">{numbers.plots}</strong>
      </div>
      <div className="bg-red-500 text-white rounded-xl shadow-lg p-10 flex items-start justify-between">
        <div>
          <TbAlertOctagon size={44} />
          <p className="text-md font-light text-gray-100 mt-2">
            Open Snags
          </p>
        </div>
        <strong className="text-4xl text-white">{numbers.openSnags}</strong>
      </div>
      <div className="bg-green-500 text-white rounded-xl shadow-lg p-10 flex items-start justify-between">
        <div>
          <TbAlertOctagon size={44} />
          <p className="text-md font-light text-gray-100 mt-2">
            Closed Snags
          </p>
        </div>
        <strong className="text-4xl text-white">{numbers.closedSnags}</strong>
      </div>
      <div className="bg-yellow-400 text-white rounded-xl shadow-lg p-10 flex items-start justify-between">
        <div>
          <TbNotes size={44} />
          <p className="text-md font-light text-gray-100 mt-2">
            Trade Contacts
          </p>
        </div>
        <strong className="text-4xl text-white">{numbers.trades}</strong>
      </div>
      <div className="bg-blue-500 text-white rounded-xl shadow-lg p-10 flex items-start justify-between">
        <div>
          <TbUsers size={44} />
          <p className="text-md font-light text-gray-100 mt-2">Account Users</p>
        </div>
        <strong className="text-4xl text-white">{numbers.users}</strong>
      </div>
    </div>
  );
};

export default CompanyOverviewComponent;
