import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SiteModel } from "../../types/api";
import Select from "react-select";

interface SiteSelectInputProps {
  value: number | undefined | null;
  onChange: (v: number | undefined | null) => void;
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#fff",
  }),
};

const SiteSelectInput: React.FC<SiteSelectInputProps> = ({
  value,
  onChange,
}) => {
  const [sites, setSites] = useState<SiteModel[]>([]);

  const getSites = useCallback(async () => {
    try {
      const { data } = await axios.get<SiteModel[]>("/admin/sites/select/list");
      setSites(data);
    } catch (error) {}
  }, []);

  useEffect(() => {
    getSites();
  }, [getSites]);

  const currentValue = useMemo(() => {
    const sel = sites.find((site) => site.id === value);

    if (sel)
      return {
        label: sel.name,
        value: sel.id,
      };
    return undefined;
  }, [value, sites]);

  return (
    <Select
      styles={customStyles}
      value={currentValue}
      onChange={(val) => onChange(val?.value)}
      options={sites.map((site) => ({ value: site.id, label: site.name }))}
    />
  );
};

export default SiteSelectInput;
