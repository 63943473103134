import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DragAndDropSortList from "../../components/DragAndDropSortList";
import axios from "../../plugins/axios";
import { RoomModel } from "../../types/api";

interface RoomsContentTabProps {}

const RoomLi = ({
  id,
  title,
  sort,
}: {
  id: number;
  title: string;
  sort: number;
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <span>{title}</span>
      <Link to={`/content/rooms/${id}`} className="text-orange-500 text-sm font-bold">
        VIEW
      </Link>
    </div>
  );
};

const RoomsContentTab: React.FC<RoomsContentTabProps> = () => {
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState<RoomModel[]>([]);

  const getRooms = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<RoomModel[]>(
        "/superadmin/content/rooms"
      );
      setTrades(data);
    } catch (error) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  const updateRoomsSorting = useCallback(
    async (res: { id: number; sort: number }[]) => {
      try {
        await axios.put("/superadmin/content/rooms/sorting", {
          rooms: res,
        });
      } catch (error) {}
    },
    []
  );

  return (
    <div>
      {loading ? (
        <div>Loading</div>
      ) : (
        <>
          <DragAndDropSortList
            onChange={(v) => {
              console.log(v);
              const payload = v.map((v, sort) => ({
                id: v as number,
                sort,
              }));
              updateRoomsSorting(payload);
            }}
            items={trades.map((trade) => ({
              id: trade.id,
              component: <RoomLi {...trade} />,
            }))}
          />
        </>
      )}
    </div>
  );
};

export default RoomsContentTab;
