import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";

interface ApiImageProps {
  imageUrl: string;
  imageProps: any;
  setDialogOpen?: any;
}

const ApiImage: React.FC<ApiImageProps> = ({ imageUrl, imageProps, setDialogOpen }) => {
  // const [loaded, setLoaded] = useState(false);
  // const [retrievedUrl, setRetrievedUrl] = useState<string>("");

  // const getImage = useCallback(async () => {
  //   try {
  //     const { data } = await axios.get<string>(`files/${imageUrl}`);
  //     setRetrievedUrl(data);
  //     setLoaded(true);
  //   } catch (error) {}
  // }, [imageUrl]);

  // useEffect(() => {
  //   getImage();
  // }, [imageUrl, getImage]);

  // if (!loaded) return <div></div>;
  return <img {...imageProps} onClick={()=>setDialogOpen(`${process.env.REACT_APP_CLOUD_FRONT_DISTRIBUTION}/${imageUrl}`)} src={`${process.env.REACT_APP_CLOUD_FRONT_DISTRIBUTION}/${imageUrl}`} alt="the alt" />;
};

export default ApiImage;
