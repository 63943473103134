import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "../plugins/axios";
import { FormProps } from "../types/app";

type Inputs = {
  title: string;
};

const CreateTradeForm: React.FC<FormProps> = ({ onCompleted }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      title: "",
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      await axios.post<{
        success: boolean;
        room_id: number;
      }>("/superadmin/content/trades", {
        title: formData.title,
      });
      onCompleted();
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Create new trade</h2>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          Title
        </label>
        <input
          id="title"
          {...register("title", { required: true, minLength: 3 })}
          placeholder="Enter trade name (3 characters min)"
          type="text"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.title && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Trade name is required and must be more than 3 characters
          </span>
        )}
      </div>
      <button
        type="submit"
        className="focus:outline-none text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-orange-900"
      >
        Create trade
      </button>
    </form>
  );
};

export default CreateTradeForm;
