import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
interface TopPlotsComponentProps {}

interface TopPlots {
  plotname: string;
  sitename: string;
  snagcount: number;
  createdby: string;
}


const TopPlotsComponent: React.FC<
TopPlotsComponentProps
> = () => {

  const [topPlots, setTopPlots] = useState<TopPlots[]>([]);

  const [loading, setLoading] = useState(true);

  const getTopTrades = useCallback(async () => {
    try {
      const { data } = await axios.get<TopPlots[]>("admin/dashboard/top-plots");
      setTopPlots(data);
      setLoading(false);
    } catch (error) {}
  }, [setTopPlots]);

  useEffect(() => {
    getTopTrades();
  }, [getTopTrades]);

  if (loading)
    return (
      <div className="p-6">
        <PulseLoader color="#fb923c" size={10} />
      </div>
    );
  return (
    <>
      <div className="grid grid-cols-1 mt-10">
        <div className="bg-white text-center">
          <h1 className="p-2 font-bold text-gray-800">Top 5 Plots [Open Snag Count]</h1>
          <hr/>
        </div>
        <table className="table-auto w-full text-left bg-white rounded-t-md">
          <thead className="mb-2 h-12 border-b border-gray-100">
            <tr className="text-gray-400 font-light">
              {["Plot Name", "Site Name", "Snag Count", "Created By"].map((header, index) => {
                if (index === 0) return <th key={header} className="p-2 pl-6">{header}</th>;
                return <th key={header}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {
              topPlots.map((plot) => {
                return (
                  <tr
                    key={plot.plotname}
                    className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
                  >
                    <td className="py-3 pl-6">
                      <p
                        className="text-orange-400 hover:text-orange-500 flex items-center justify-start"
                      >
                        {plot.plotname}
                      </p>
                    </td>
                    <td className="py-2">{plot.sitename}</td>
                    <td className="py-2">{plot.snagcount}</td>
                    <td className="py-2">{plot.createdby}</td>
                  </tr>
                )  
              })
                
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TopPlotsComponent;
