import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface MenuItemProps {
  title: string;
  path: string;
  icon: any;
}

const MenuItem: React.FC<MenuItemProps> = ({ title, path, icon }) => {
  const location = useLocation();

  const active = useMemo<boolean>(() => {
    if (path === location.pathname) return true;

    if (path !== "/" && location.pathname.includes(path)) return true;

    return false;
  }, [location, path]);

  return (
    <Link to={path}>
      <div
        role="link"
        className={`my-4 flex items-center py-3 px-6 hover:bg-orange-400 hover:text-white rounded-md text-xl ${
          active ? "bg-orange-400 text-white" : ""
        }`}
      >
        {icon} <span className="ml-2 text-sm">{title}</span>
      </div>
    </Link>
  );
};

export default MenuItem;
