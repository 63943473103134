import Tabs from "../../components/Tabs";
import React, { useCallback, useEffect, useState } from "react";
import ListViewHeader from "../../components/ListViewHeader";
import ManageUsersTab from "./ManageUsersTab";
import ManageUserAccessTab from "./ManageAccessTab";
import { PulseLoader } from "react-spinners";
import { CompanyModel } from "../../types/api";
import axios from "../../plugins/axios";

interface SettingsScreenProps {}

const SettingsScreen: React.FC<SettingsScreenProps> = () => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<CompanyModel | undefined>(undefined);

  const getCompany = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await axios.get<CompanyModel>("admin/company");
      setCompany(data);
    } catch (error) {}
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  if (loading)
    return (
      <div>
        <PulseLoader color="#fb923c" size={10} />
      </div>
    );
  if (!company) return <div>Failed to load company.</div>;

  return (
    <>
      <ListViewHeader title="Account Settings" />
      <div className="px-6">
        <Tabs
          tabs={[
            {
              id: "users",
              title: "Account users",
              component: <ManageUsersTab />,
            },
            {
              id: "access-control",
              title: "Access Control",
              component: (
                <ManageUserAccessTab
                  company={company}
                  getCompany={getCompany}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default SettingsScreen;
