import React, { useEffect, useState } from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { CgArrowsV } from "react-icons/cg";
type DnDItem = {
  id: number | string;
  component: any;
};

interface DragAndDropSortListProps {
  items: DnDItem[];
  onChange: (sorted: number[] | string[]) => void;
}

const DragHandle = SortableHandle(() => (
  <span className="cursor-move block border-gray-300 border p-1 mr-3 text-gray-300 hover:text-black hover:border-black">
    <CgArrowsV />
  </span>
));

const SortableItem = SortableElement<{ value: any }>(
  ({ value }: { value: any }) => (
    <div className="p-3 bg-white mb-2 rounded-sm shadow-sm flex items-center">
      <DragHandle />
      {value}
    </div>
  )
);

const SortableList = SortableContainer<{ items: DnDItem[] }>(
  ({ items }: { items: DnDItem[] }) => {
    return (
      <div className="max-w-4xl">
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item.id}`}
            index={index}
            value={item.component}
          />
        ))}
      </div>
    );
  }
);

const DragAndDropSortList: React.FC<DragAndDropSortListProps> = ({
  items,
  onChange,
}) => {
  const [currentItems, setCurrentItems] = useState<DnDItem[]>(items);

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);
  const onSortEnd = ({ newIndex, oldIndex }: any) => {
    const newItemsSort = arrayMove(currentItems, oldIndex, newIndex);
    setCurrentItems(newItemsSort);
    onChange(newItemsSort.map((it) => it.id) as number[]);
  };
  
  return (
    <SortableList items={currentItems} onSortEnd={onSortEnd} useDragHandle />
  );
};

export default DragAndDropSortList;
