import axios from "../../../plugins/axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ActivityLogModel, PaginatedType } from "../../../types/api";
import useCustomSearchParams from "../../../utils/useCustomSearchParams";
import Paginator from "../../../components/Paginator";

interface ActivityLogProps {
  companyId: number;
}

const ActivityLog: React.FC<ActivityLogProps> = ({ companyId = null }) => {
  const [loading, setLoading] = useState(false);
  const [logItems, setLogItems] = useState<ActivityLogModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "100"),
    };
  }, [search.page, search.per_page]);

  const getLogItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<ActivityLogModel[]>>(
        "/superadmin/activity-log",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
            company: companyId,
          },
        }
      );
      setLogItems(data.items);
      setTotal(data.total);
    } catch (error) {}
    setLoading(false);
  }, [companyId, pagination.current_page, pagination.per_page]);

  useEffect(() => {
    getLogItems();
  }, [getLogItems, pagination, search.search]);

  return (
    <div>
      {loading && <div className="my-2">Loading...</div>}
      <table className="table-auto w-full text-left bg-white rounded-t-md">
        <thead className="mb-2 h-12 border-b border-gray-100">
          <tr className="text-gray-400 font-light">
            <th className="p-2 pl-6">Type</th>
            <th>Description</th>
            <th>Caused By</th>
            <th>On Model</th>
            <th>Timestamp</th>

            <th className="w-10"></th>
          </tr>
        </thead>
        <tbody>
          {logItems.map((logItem) => (
            <tr
              key={logItem.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">{logItem.log_name}</td>
              <td className="py-2">{logItem.description}</td>
              <td className="py-2">
                {logItem.causer_type}(<strong>{logItem.causer_id}</strong>)
              </td>
              <td className="py-2">
                {logItem.subject_type}(<strong>{logItem.subject_id}</strong>)
              </td>

              <td className="py-2">
                {new Date(logItem.created_at).toLocaleDateString()}
              </td>
              <td>
                {/* <Link
                  to={`/companies/${logItem.id}`}
                  className="text-gray-400 hover:text-orange-500 cursor-pointer"
                >
                  <HiOutlineDotsHorizontal size={20} />
                </Link> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {total > 0 && (
        <Paginator
          total={total}
          currentPage={pagination.current_page}
          perPage={pagination.per_page}
        />
      )}
    </div>
  );
};

export default ActivityLog;
