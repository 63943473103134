import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "../plugins/axios";
import { FormProps } from "../types/app";
import MultiSiteSelectInput from "../components/Inputs/MultiSiteSelect";

type Inputs = {
  email: string;
  owner: boolean;
  sendInstructionEmail: boolean;
  sites: number[];
};

interface InviteUserToCompanyFormProps extends FormProps {
  companyId?: number;
}

const InviteUserToCompanyForm: React.FC<InviteUserToCompanyFormProps> = ({
  companyId = undefined,
  onCompleted,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: "",
      owner: false,
      sendInstructionEmail: false,
      sites: [],
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      console.log('formData --->', formData)
      await axios.post<{
        success: boolean;
        company_id: number;
      }>(
        companyId
          ? `/superadmin/companies/${companyId}/invites`
          : "/admin/users/invites",
        {
          email: formData.email,
          owner: formData.owner,
          send_instruction_email: formData.sendInstructionEmail,
          sites: JSON.stringify(formData.sites),
        }
      );
      onCompleted();
      // @ts-ignore
    } catch (error: AxiosError<any>) {
      const status = error.response.status;

      if (status === 400) {
        switch (error.response.data.detail) {
          case "user_exists":
            toast("A user already exists with that email address", {
              type: "error",
            });
            break;

          default:
            toast("Failed to send invite for unknown reason", {
              type: "error",
            });
            break;
        }
      } else {
        toast("Failed to send invite for unknown reason", {
          type: "error",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-xl font-medium mb-4">Invite User</h2>
      <div className="my-6">
        <label htmlFor="name" className="text-sm font-medium mb-2">
          User Email address
        </label>
        <input
          id="name"
          {...register("email", { required: true, minLength: 3 })}
          placeholder="Enter email address"
          type="email"
          className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
        />
        {errors.email && (
          <span className="mt-2 text-xs text-red-600 font-medium">
            Email address is required is required and must be more than 3
            characters
          </span>
        )}
      </div>
      <div className="my-6">
        <label htmlFor="email" className="text-sm font-medium mb-3">
          Select Sites For Access (Optional)
        </label>

        <Controller
          name="sites"
          control={control}
          rules={{ required: false }}
          render={({ field: { value, onChange } }) => (
            <MultiSiteSelectInput companyId={companyId} onChange={onChange} />
          )}
        />
      </div>

      <div className="my-6">
        <label className="inline-flex items-center">
          <input
            {...register("owner")}
            type="checkbox"
            className="rounded bg-white border-transparent focus:border-transparent text-gray-700 ring-1 ring-gray-300 focus:ring-offset-2 focus:ring-gray-500"
          />{" "}
          <span className="ml-2">Make user an admin?</span>
        </label>
      </div>
      <div className="my-6">
        <label className="inline-flex items-center">
          <input
            {...register("sendInstructionEmail")}
            type="checkbox"
            className="rounded bg-white border-transparent focus:border-transparent text-gray-700 ring-1 ring-gray-300 focus:ring-offset-2 focus:ring-gray-500"
          />{" "}
          <span className="ml-2">Send instruction email?</span>
        </label>
      </div>
      <button
        type="submit"
        className="focus:outline-none text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-orange-900"
      >
        Send invite
      </button>
    </form>
  );
};

export default InviteUserToCompanyForm;
