import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import CognitoUser from "../../components/CognitoUser";
import { InviteModel } from "../../types/api";
import InviteUserToCompanyForm from "../../forms/InviteUserToCompanyForm";
import Modal from "../../components/Modal";
import { TiUserDelete } from "react-icons/ti";
import { toast } from "react-toastify";

const ManageUsersTab: React.FC = () => {
  const [invites, setInvites] = useState<InviteModel[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const [users, setUsers] = useState<{ id: number }[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingInvites, setLoadingInvites] = useState(true);

  const getInvites = useCallback(async () => {
    setLoadingInvites(true);
    try {
      const { data } = await axios.get<InviteModel[]>(`/admin/users/invites`);
      setInvites(data);
    } catch (error) {
      setInvites([]);
    }
    setLoadingInvites(false);
  }, []);

  const getUsers = useCallback(async () => {
    setLoadingUsers(true);
    try {
      const { data } = await axios.get<{ id: number }[]>(`/admin/users`);
      setUsers(data);
    } catch (error) {
      setUsers([]);
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getInvites();
    getUsers();
  }, [getUsers, getInvites]);

  const deleteUser = async (id: number) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to delete this user, this cannot be undone."
      )
    ) {
      try {
        await axios.delete(`/admin/users/${id}`);
        toast("User Deleted successfully", { type: "success" });
        getInvites();
        getUsers();
      } catch (error) {
        toast("Failed to delete user.", { type: "error" });
      }
    }
  };

  const deleteInvite = async (id: number) => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Are you sure you want to delete this invite, you will need to resend if you want the user to join your team"
      )
    ) {
      try {
        await axios.delete(`/admin/users/invites/${id}`);
        toast("Invite deleted successfully", { type: "success" });
        getInvites();
        getUsers();
      } catch (error) {
        toast("Failed to delete user.", { type: "error" });
      }
    }
  };

  return (
    <div>
      <h3 className="my-4 font-medium text-lg">Active Users</h3>
      <div className="max-w-4xl rounded-md overflow-hidden">
        {users.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-6 bg-orange-400 text-white font-medium">
              <div className="col-span-3">Name</div>
              <div className="col-span-2">Role</div>
              <div></div>
            </div>
            {users.map((user, index) => (
              <CognitoUser
                key={user.id}
                type="admin"
                userId={user.id}
                render={(userD) => (
                  <div
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                    } p-3 grid grid-cols-6`}
                  >
                    <div className="col-span-3">
                      {userD.name}({userD.email})
                    </div>
                    <div className="col-span-2">
                      {userD.owner ? "Admin" : "User"}
                    </div>
                    <div className="text-right">
                      {" "}
                      <button
                        className="text-red-500 hover:text-red-600"
                        title="Remove User"
                        onClick={() => deleteUser(user.id)}
                      >
                        <TiUserDelete size={20} />
                      </button>
                    </div>
                  </div>
                )}
              />
            ))}
          </>
        ) : (
          <>
            {loadingUsers ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Users List
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                You do not currently have any active users, invite a user below.
              </div>
            )}
          </>
        )}
      </div>
      <hr className="my-10" />
      <h3 className="my-4 font-medium text-lg">Pending Invites</h3>
      <div className="max-w-4xl rounded-md overflow-hidden mb-10">
        {invites.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-6 bg-orange-400 text-white font-medium">
              <div className="col-span-3">Email</div>
              <div className="col-span-2">Role</div>
              <div></div>
            </div>
            {invites.map((invitee, index) => (
              <div
                key={invitee.id}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                } p-3 grid grid-cols-6`}
              >
                <div className="col-span-3">{invitee.email}</div>
                <div className="col-span-2">
                  {invitee.owner ? "Admin" : "User"}
                </div>
                <div className="text-right">
                  <button
                    className="text-red-500 hover:text-red-600"
                    title="Remove Invite"
                    onClick={() => deleteInvite(invitee.id)}
                  >
                    <TiUserDelete size={20} />
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {loadingInvites ? (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                Loading Invites
              </div>
            ) : (
              <div className="p-6 bg-gray-200 font-medium text-gray-600">
                No pending invites
              </div>
            )}
          </>
        )}
      </div>

      <button
        className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
        onClick={() => setShowAdd(true)}
      >
        Invite User
      </button>
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <InviteUserToCompanyForm
          onCompleted={async () => {
            await getUsers();
            await getInvites();

            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ManageUsersTab;
