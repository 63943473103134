import React from "react";
import CompanyOverviewComponent from "../components/Dashboard/CompanyOverview";
import TopTradesComponent from "../components/Dashboard/TopTrades";
import TopPlotsComponent from "../components/Dashboard/TopPlots";

interface DashboardProps {
  isSuperAdmin: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isSuperAdmin }) => {
  if (isSuperAdmin)
    return <div className="p-3">No super admin dashboard has been setup</div>;
  return (
    <div className="p-10">
      <h1 className="font-bold text-xl text-orange-500">Dashboard</h1>
      <CompanyOverviewComponent />
      <TopPlotsComponent />
      <TopTradesComponent />
    </div>
  );
};

export default Dashboard;
