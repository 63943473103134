import React, { useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { PlotModel } from "../../../types/api";
import axios from "../../../plugins/axios";
import { toast } from "react-toastify";
import SiteSelectInput from "../../../components/Inputs/SiteSelect";
import { useParams } from "react-router-dom";

interface PlotDetailsTabProps {
  plot: PlotModel;
  getPlot: () => Promise<void>;
}

type Inputs = {
  name: string;
  site: number;
  stage_inspection: string;
};

const PlotDetailsTab: React.FC<PlotDetailsTabProps> = ({ plot, getPlot }) => {
  let { plotId, siteId } = useParams();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control } = useForm<Inputs>({
    defaultValues: {
      ...plot,
      site: plot.site.id
    },
  });

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (loading) return;

    setLoading(true);
    try {
      const fd = {
        ...formData,
        site: {
          id: formData.site
        },
        users: JSON.stringify(plot.users),
      }
      await axios.put(`/admin/plots/${plot.id}`, fd);
      toast("Plot details saved!", { type: "success" });
      getPlot();
    } catch (error) {
      toast("Failed to update plot details", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Plot Name:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("name", { required: true, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Site:</label>
          <Controller
            name="site"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <SiteSelectInput value={value} onChange={onChange} />
            )}
          />
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Inspection Stage:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("stage_inspection", { required: false, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div>
          <button
            type="submit"
            disabled={loading}
            className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
          >
            {loading ? "Loading..." : "Save Plot details"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PlotDetailsTab;
