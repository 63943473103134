import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ListViewHeader from "../../components/ListViewHeader";
import { CompanyModel, PaginatedType } from "../../types/api";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import axios from "../../plugins/axios";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import Modal from "../../components/Modal";
import CreateCompanyForm from "../../forms/CreateCompanyForm";
import ApiImage from "../../components/ApiImage";
import Table from "../../components/Table";

const CompaniesListScreen: React.FC = () => {
  const [error, setError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);
  const [showAdd, setShowAdd] = useState(false);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getCompaniesList = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<CompanyModel[]>>(
        "/superadmin/companies",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: search.search,
          },
        }
      );
      setCompanies(data.items);
      setTotal(data.total);
    } catch (error) {
      setError(true);
      setCompanies([]);
      setTotal(0);
    }
    setLoading(false);
  }, [pagination, search.search]);

  useEffect(() => {
    getCompaniesList();
  }, [getCompaniesList, pagination, search.search]);

  return (
    <div>
      <ListViewHeader
        title="Companies"
        showSearch
        onCreate={() => setShowAdd(true)}
      />
      <Table
        total={total}
        loading={loading}
        error={error}
        rows={companies}
        pagination={pagination}
        headers={[
          "Company Name",
          "Users",
          "Enterprise Status",
          "No. of Plots",
          "No. of Snags",
          "Active Since",
          "",
        ]}
        messages={{
          noResults: "No activity log items found",
          apiFailed: "Failed to load event log list",
        }}
        renderRow={(company) => {
          return (
            <tr
              key={company.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">
                <Link
                  to={`/companies/${company.id}`}
                  className="text-orange-400 hover:text-orange-500 flex items-center justify-start"
                >
                  {company.logo && (
                    <ApiImage
                      imageUrl={company.logo}
                      imageProps={{
                        alt: company.name,
                        className: "w-10 h-10 contain rounded-md mr-4",
                      }}
                    />
                  )}
                  {company.name}
                </Link>
              </td>
              <td className="py-2">{company.users.length}</td>
              <td className="py-2" style={{
                color: company.enterprise
                  ? company.enterprise_renewed
                    ? "green"
                    : "red"
                  : "gray",
                fontWeight: "bold"
              }}>
                {company.enterprise
                  ? company.enterprise_renewed
                    ? "Active"
                    : "Expired"
                  : "None"}
              </td>
              <td className="py-2">
                {company?.plots?.length}
              </td>
              <td className="py-2">
                {
                  company?.plots?.reduce((acc: number, plot: { snags: [] }) => acc + plot.snags.length, 0)
                }
              </td>
              <td className="py-2">
                {new Date(company.created_at).toLocaleDateString()}
              </td>
              <td>
                <Link
                  to={`/companies/${company.id}`}
                  className="text-gray-400 hover:text-orange-500 cursor-pointer"
                >
                  <HiOutlineDotsHorizontal size={20} />
                </Link>
              </td>
            </tr>
          );
        }}
      />
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <CreateCompanyForm
          onCompleted={async () => {
            await getCompaniesList();
            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default CompaniesListScreen;
