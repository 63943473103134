import axios from "../../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import CognitoUser from "../../../components/CognitoUser";
import { CompanyModel, InviteModel } from "../../../types/api";
import InviteUserToCompanyForm from "../../../forms/InviteUserToCompanyForm";
import Modal from "../../../components/Modal";
import { toast } from "react-toastify";

interface CompanyUsersProps {
  company: CompanyModel;
}

const CompanyUsers: React.FC<CompanyUsersProps> = ({ company }) => {
  const [invites, setInvites] = useState<InviteModel[]>([]);
  const [showAdd, setShowAdd] = useState(false);
  const getInvitesList = useCallback(async () => {
    try {
      const { data } = await axios.get<InviteModel[]>(
        `/superadmin/companies/${company.id}/invites`
      );
      setInvites(data);
    } catch (error) {}
  }, [company]);

  useEffect(() => {
    getInvitesList();
  }, [getInvitesList]);

  return (
    <div>
      <h3 className="my-4 font-medium text-lg">Active Users</h3>
      <div className="max-w-4xl rounded-md overflow-hidden">
        {company.users.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-4 bg-orange-400 text-white font-medium">
              <div className="col-span-3">Name</div>
              <div>Role</div>
            </div>
            {company.users.map((user, index) => (
              <CognitoUser
                userId={user}
                render={(userD) => (
                  <div
                    className={`${
                      index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                    } p-3 grid grid-cols-4`}
                  >
                    <div className="col-span-3">
                      {userD.name}({userD.email})
                    </div>
                    <div>{userD.owner ? "Admin" : "User"}</div>
                  </div>
                )}
              />
            ))}
          </>
        ) : (
          <div className="p-6 bg-gray-200 font-medium text-gray-600">
            {company.name} does not currently have any active users, invite a
            user below.
          </div>
        )}
      </div>
      <hr className="my-10" />
      <h3 className="my-4 font-medium text-lg">Pending Invites</h3>
      <div className="max-w-4xl rounded-md overflow-hidden mb-10">
        {invites.length > 0 ? (
          <>
            <div className="p-3 grid grid-cols-4 bg-orange-400 text-white font-medium">
              <div className="col-span-3">Email</div>
              <div>Role</div>
            </div>
            {invites.map((invitee, index) => (
              <div
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                } p-3 grid grid-cols-4`}
              >
                <div className="col-span-3">{invitee.email}</div>
                <div>{invitee.owner ? "Admin" : "User"}</div>
              </div>
            ))}
          </>
        ) : (
          <div className="p-6 bg-gray-200 font-medium text-gray-600">
            No pending invites
          </div>
        )}
      </div>

      <button
        className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
        onClick={() => setShowAdd(true)}
      >
        Invite User
      </button>
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <InviteUserToCompanyForm
          companyId={company.id}
          onCompleted={async () => {
            await getInvitesList();
            setShowAdd(false);
            toast("Invite sent successfully");
          }}
        />
      </Modal>
    </div>
  );
};

export default CompanyUsers;
