import ListViewHeader from "../../components/ListViewHeader";
import Tabs from "../../components/Tabs";
import TradesContentTab from "./trades";
import RoomsContentTab from "./rooms";
import Modal from "../../components/Modal";
import { useState } from "react";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import CreateRoomForm from "../../forms/CreateRoomForm";
import CreateTradeForm from "../../forms/CreateTradeForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ContentScreen = () => {
  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useState<"rooms" | "trades" | undefined>(
    undefined
  );
  const [search] = useCustomSearchParams();

  return (
    <div>
      <ListViewHeader
        title="Content"
        onCreate={() => {
          setShowAdd(search.active_tab ?? "trades");
        }}
      />
      <div className="pb-4 px-6 relative">
        <Tabs
          tabs={[
            {
              id: "trades",
              title: "Trades",
              component: <TradesContentTab />,
            },
            {
              id: "rooms",
              title: "Rooms",
              component: <RoomsContentTab />,
            },
            // {
            //   id: "snag_types",
            //   title: "Snag Types",
            //   component: <SnagLocationsContentTab />,
            // },
          ]}
        />
      </div>
      <Modal shown={!!showAdd} onClose={() => setShowAdd(undefined)}>
        {showAdd !== undefined && (
          <>
            {showAdd === "trades" ? (
              <CreateTradeForm
                onCompleted={async () => {
                  navigate(0);
                  toast("Trade created successfully");
                }}
              />
            ) : (
              <CreateRoomForm
                onCompleted={async () => {
                  navigate(0);
                  toast("Room created successfully");
                }}
              />
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ContentScreen;
