import React from "react";
import { PulseLoader } from "react-spinners";
import NoResultsComponent from "../NoResultsComponent";
import Paginator from "../Paginator";

interface TableProps {
  total: number;
  rows: object[];
  headers: string[];
  renderRow: (row: any) => JSX.Element;
  loading: boolean;
  error: boolean;
  messages?: {
    noResults?: any;
    apiFailed?: any;
  };
  pagination: {
    current_page: number;
    per_page: number;
  };
}

const Table: React.FC<TableProps> = ({
  total,
  rows,
  headers,
  renderRow,
  loading,
  error,
  pagination,
  messages = {
    noResults: "No matching rows",
    apiFailed: "Failed to load results, please try again later",
  },
}) => {
  return (
    <div className="py-4 px-6 relative">
      <div className="relative" style={{ minHeight: 300 }}>
        <table className="table-auto w-full text-left bg-white rounded-t-md">
          <thead className="mb-2 h-12 border-b border-gray-100">
            <tr className="text-gray-400 font-light">
              {headers.map((header, index) => {
                if (index === 0) return <th className="p-2 pl-6">{header}</th>;
                if (header === "") return <th className="w-10"></th>;
                return <th key={header}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>{rows.map((row) => renderRow(row))}</tbody>
        </table>
        {loading && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-50 rounded-lg text-orange-400">
            <PulseLoader color="#fb923c" size={10}/>
          </div>
        )}
        {total > 0 && (
          <Paginator
            total={total}
            currentPage={pagination.current_page}
            perPage={pagination.per_page}
          />
        )}
        {!total && !loading && (
          <>
            {error ? (
              <NoResultsComponent message={messages.apiFailed} />
            ) : (
              <NoResultsComponent message={messages.noResults} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Table;
