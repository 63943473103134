import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ListViewHeader from "../../components/ListViewHeader";
import { PaginatedType, TradeModel } from "../../types/api";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import axios from "../../plugins/axios";
import useCustomSearchParams from "../../utils/useCustomSearchParams";
import Modal from "../../components/Modal";
import CreateContactForm from "../../forms/CreateContactForm";
import Table from "../../components/Table";

const TradesListScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [trades, setTrades] = useState<TradeModel[]>([]);
  const [search] = useCustomSearchParams();
  const [total, setTotal] = useState(0);
  const [showAdd, setShowAdd] = useState(false);

  const pagination = useMemo(() => {
    return {
      current_page: parseInt(search.page ?? "1"),
      per_page: parseInt(search.per_page ?? "20"),
    };
  }, [search.page, search.per_page]);

  const getCompaniesList = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<PaginatedType<TradeModel[]>>(
        "/admin/trades",
        {
          params: {
            page: pagination.current_page,
            per_page: pagination.per_page,
            search: search.search,
          },
        }
      );
      setTrades(data.items);
      setTotal(data.total);
    } catch (error) {
      setError(true);
      setTrades([]);
      setTotal(0);
    }
    setLoading(false);
  }, [pagination, search.search]);

  useEffect(() => {
    getCompaniesList();
  }, [getCompaniesList, pagination, search.search]);

  return (
    <div>
      <ListViewHeader
        title="Trades"
        showSearch
        onCreate={() => setShowAdd(true)}
      />
      <Table
        total={total}
        loading={loading}
        error={error}
        rows={trades}
        pagination={pagination}
        headers={["Name", "Company Name", "Email", "Type", ""]}
        messages={{
          noResults: (
            <div>
              You have not created any trades,{" "}
              <span
                role="button"
                onClick={() => setShowAdd(true)}
                className="text-orange-500"
              >
                create one
              </span>
              .
            </div>
          ),
          apiFailed: "Failed to load trades",
        }}
        renderRow={(trade) => {
          return (
            <tr
              key={trade.id}
              className="my-2 p-3 border-b border-gray-50 last:border-b-0 text-gray-500"
            >
              <td className="py-3 pl-6">
                <Link
                  to={`/trades/${trade.id}`}
                  className="text-orange-400 hover:text-orange-500 flex items-center justify-start"
                >
                  {trade.name}
                </Link>
              </td>
              <td className="py-2">{trade.company_name}</td>
              <td className="py-2">{trade.email}</td>

              <td className="py-2">{trade.trade.title}</td>
              <td>
                <Link
                  to={`/trades/${trade.id}`}
                  className="text-gray-400 hover:text-orange-500 cursor-pointer"
                >
                  <HiOutlineDotsHorizontal size={20} />
                </Link>
              </td>
            </tr>
          );
        }}
      />
      <Modal shown={showAdd} onClose={() => setShowAdd(false)}>
        <CreateContactForm
          onCompleted={async () => {
            getCompaniesList();
            setShowAdd(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default TradesListScreen;
