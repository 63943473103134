import React from "react";
import { IoSettingsOutline } from "react-icons/io5";
import ApiImage from "../../../components/ApiImage";
import { CompanyModel } from "../../../types/api";
import useCustomSearchParams from "../../../utils/useCustomSearchParams";

interface CompanyDetailsProps {
  company: CompanyModel;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ company }) => {
  const [search, setSearch] = useCustomSearchParams();

  return (
    <div>
      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-start p-4 mb-4">
        {company.logo && (
          <ApiImage
            imageUrl={company.logo}
            imageProps={{
              alt: company.name,
              className: "w-20 h-20 contain rounded-md mr-4",
            }}
          />
        )}
        <span className="text-lg font-medium">{company.name}</span>
      </div>

      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-start p-4 text-lg text-gray-500 mb-4">
        Joined: &nbsp;
        <span className="text-orange-400 font-medium">
          {new Date(company.created_at).toLocaleDateString()}
        </span>
      </div>

      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-between p-4 text-lg text-gray-500 mb-4">
        <div>
          Enterprise: &nbsp;
          <span className="text-orange-400 font-medium">
            {company.enterprise
              ? `Yes (${company.enterprise_renewed ? "Active" : "Inactive"})`
              : "No"}
          </span>
        </div>
        <div
          role="button"
          className="hover:text-black"
          onClick={() => setSearch({ ...search, active_tab: "actions" })}
        >
          <IoSettingsOutline size={24} />
        </div>
      </div>

      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-start p-4 text-lg text-gray-500 mb-4">
        Active Plots: &nbsp;
        <span className="text-orange-400 font-medium">
          {company.plot_count}
        </span>
      </div>
      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-start p-4 text-lg text-gray-500 mb-4">
        Snags: &nbsp;
        <span className="text-orange-400 font-medium">
          {company.snags_count}
        </span>
      </div>
      <div className="max-w-4xl bg-gray-200 rounded-lg flex items-center justify-start p-4 text-lg text-gray-500 mb-4">
        Contacts: &nbsp;
        <span className="text-orange-400 font-medium">
          {company.contact_count}
        </span>
      </div>
    </div>
  );
};

export default CompanyDetails;
