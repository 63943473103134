import React, { useCallback, useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { TradeModel } from "../../types/api";
import axios from "../../plugins/axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface TradeShowScreenProps {}

type Inputs = {
  name: string;
  company_name: string;
  email: string;
};
const TradeShowScreen: React.FC<TradeShowScreenProps> = () => {
  let { tradeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [trade, setTrade] = useState<TradeModel | undefined>(undefined);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue } = useForm<Inputs>({
    defaultValues: {
      name: "",
      company_name: "",
      email: "",
    },
  });

  const getTradeById = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<TradeModel>(`/admin/trades/${tradeId}`);
      setTrade(data);
      setValue("name", data.name);
      setValue("company_name", data.company_name);
      setValue("email", data.email);
    } catch (error) {}
    setLoading(false);
  }, [tradeId, setValue]);

  useEffect(() => {
    getTradeById();
  }, [getTradeById]);

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      await axios.put(`/contacts/${tradeId}`, formData);
      getTradeById();
      toast("Contact successfully updated", { type: "success" });
    } catch (error) {}
  };

  if (!trade || loading)
    return (
      <div className="p-6 text-gray-500 font-medium text-lg">
        Loading trade details...
      </div>
    );
  return (
    <div className="p-6">
      <div
        role="button"
        className="flex items-center text-xs font-medium text-gray-500 hover:text-black"
        onClick={() => {
          navigate("/trades");
        }}
      >
        <IoArrowBackOutline className="mr-2" />
        Back to search
      </div>
      <h1 className="text-orange-400 text-xl font-medium my-6">{trade.name}</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Contact Name:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("name", { required: true, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Company Name:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("company_name", { required: true, minLength: 3 })}
            type="text"
            required
          ></input>
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Email Address:</label>
          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block w-full"
            {...register("email", { required: true, minLength: 3 })}
            type="email"
            required
          ></input>
        </div>

        <div className="max-w-3xl text-lg text-gray-500 mb-4">
          <label className="block mb-1">Trade Type:</label>

          <input
            className="text-orange-400 font-medium bg-gray rounded-md border-none block bg-white px-3 text-base py-2 w-full"
            value={trade.trade.title}
            required
            disabled
          ></input>
        </div>
        <div>
          <button
            type="submit"
            onClick={() => {}}
            className="bg-orange-400 text-white hover:bg-orange-500 p-2 px-6 rounded-md shadow-sm text-sm"
          >
            Save Trade
          </button>
        </div>
      </form>
    </div>
  );
};

export default TradeShowScreen;
