import React, { useMemo } from "react";
import {
  IoBusinessOutline,
  IoDocumentOutline,
  IoEaselOutline,
  IoHomeOutline,
  IoListOutline,
  IoLogOutOutline,
  IoPeopleOutline,
  IoSettingsOutline,
} from "react-icons/io5";

import MenuItem from "./MenuItem";
import { useAuthenticator } from "@aws-amplify/ui-react";

const Sidebar: React.FC = () => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const isSuperAdmin = useMemo(
    () =>
      user
        ?.getSignInUserSession()
        ?.getIdToken()
        .payload["cognito:groups"].includes("SuperAdmins"),
    [user]
  );

  const getInitials = (name: String) => name != ''
  ? name.trim().split(RegExp(' +')).map((s) => s[0]).join('').toUpperCase()
  : '';

  return (
    <div className="bg-white h-screen sticky top-0 flex flex-col items-start justify-between">
      <div className="w-full">
        <img
          src="/images/logo.png"
          alt="Plotsnag logo"
          className="w-20 h-20 m-4"
        />
        <div className="p-4 w-full ">
          {isSuperAdmin ? (
            <>
              <MenuItem title="Dashboard" path="/" icon={<IoHomeOutline />} />
              <MenuItem
                title="Companies"
                path="/companies"
                icon={<IoBusinessOutline />}
              />
              <MenuItem
                title="Users"
                path="/users"
                icon={<IoPeopleOutline />}
              />
              <hr />
              <MenuItem
                title="Content"
                path="/content"
                icon={<IoDocumentOutline />}
              />
              <MenuItem
                title="Event Log"
                path="/activity-log"
                icon={<IoListOutline />}
              />
            </>
          ) : (
            <>
              <MenuItem title="Dashboard" path="/" icon={<IoHomeOutline />} />
              <MenuItem
                title="Sites/Projects"
                path="/sites"
                icon={<IoBusinessOutline />}
              />
              <hr />
              <MenuItem
                title="Trade Directory"
                path="/trades"
                icon={<IoEaselOutline />}
              />
              <MenuItem
                title="Event Log"
                path="/activity-log"
                icon={<IoListOutline />}
              />
              <MenuItem
                title="Settings"
                path="/settings"
                icon={<IoSettingsOutline />}
              />
            </>
          )}
          {/* <MenuItem
            title="Settings"
            path="/settings"
            icon={<IoSettingsOutline />}
          /> */}
        </div>
      </div>
      <div className="w-full">
        <div className="p-4 pl-10 flex items-center">
          <div className="bg-blue-500 w-10 h-10 rounded-full text-white flex items-center justify-center">
            {getInitials(user.attributes!.nickname)}
          </div>
          <div className="ml-2">
            <h5 className="text-sm font-medium">{user.attributes!.nickname}</h5>
            <h6 className="text-xs text-gray-400">{user.attributes?.email}</h6>
          </div>
        </div>
        <div
          className="bg-gray-200 w-full p-4 hover:bg-black hover:text-white transition duration-150 ease-in-out flex items-center pl-10"
          role="button"
          onClick={async () => {
            localStorage.clear();
            signOut();
          }}
        >
          <IoLogOutOutline className="mr-2" size={27} />
          Logout
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
