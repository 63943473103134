import React, { useCallback, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import DragAndDropSortList from "../../components/DragAndDropSortList";
import axios from "../../plugins/axios";
import { TradeContentModel } from "../../types/api";

interface TradesContentTabProps {}

const TradeLi = ({
  id,
  title,
  sort,
}: {
  id: number;
  title: string;
  sort: number;
}) => {
  return <div>{title}</div>;
};

const TradesContentTab: React.FC<TradesContentTabProps> = () => {
  const [loading, setLoading] = useState(false);
  const [trades, setTrades] = useState<TradeContentModel[]>([]);

  const getLogItems = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<TradeContentModel[]>(
        "/superadmin/content/trades"
      );
      setTrades(data);
    } catch (error) {}
    setLoading(false);
  }, []);

  useEffect(() => {
    getLogItems();
  }, [getLogItems]);

  const updateLogItemsSorting = useCallback(
    async (res: { id: number; sort: number }[]) => {
      try {
        await axios.put("/superadmin/content/trades/sorting", {
          trades: res,
        });
      } catch (error) {}
    },
    []
  );

  return (
    <div>
      {loading && <PulseLoader color="#fb923c" size={10} />}
      <DragAndDropSortList
        onChange={(v) => {
          const payload = v.map((v, sort) => ({
            id: v as number,
            sort,
          }));
          updateLogItemsSorting(payload);
        }}
        items={trades.map((trade) => ({
          id: trade.id,
          component: <TradeLi {...trade} />,
        }))}
      />
    </div>
  );
};

export default TradesContentTab;
