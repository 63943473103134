import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import { SiteModel } from "../../types/api";
import Select from "react-select";

interface MultiSiteSelectInputProps {
  onChange: (v: number[]) => void;
  companyId?: number;
}

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any) => ({
    ...provided,
    width: "100%",
    backgroundColor: "#fff",
  }),
};

const MultiSiteSelectInput: React.FC<MultiSiteSelectInputProps> = ({
  onChange,
  companyId
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState<SiteModel[]>([]);

  const getSites = useCallback(async () => {
    try {
      let url =  "/admin/sites/select/list";
      if (companyId) {
        url = `/superadmin/companies/${companyId}/sites`;
      }
      const { data } = await axios.get<SiteModel[]>(url);
      setSites(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <Select
      styles={customStyles}
      isMulti={true}
      onChange={(val) => {
        onChange(val.map((v: any) => v.value));
      }}
      noOptionsMessage={() => "No sites found"}
      isLoading={isLoading}
      loadingMessage={() => "Loading your sites..."}
      options={sites.map((site) => ({ value: site.id, label: site.name }))}
    />
  );
};

export default MultiSiteSelectInput;
